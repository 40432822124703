import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { RichText } from "prismic-reactjs"

const Container = styled.div`
  .swiper-button-disabled {
    visibility: hidden;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
  }
`
const SwipeContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.body ? " 3fr 2fr" : "1fr ")};
  grid-gap: 2rem;
  padding: 0 3rem;
  overflow: visible;
  img {
    max-height: 60vh;
  }
  @media only screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`

function SwipeSlider(props) {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
  const slider = props.slider
  return (
    <>
      <Container>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoHeight
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          onSwiper={swiper => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {slider.map(slide => (
            <SwiperSlide>
              <SwipeContainer
                body={slide.slidebody && slide.slidebody[0].text !== ""}
              >
                <img src={slide.image.url} alt="" />
                {slide.slidebody && RichText.render(slide.slidebody)}
              </SwipeContainer>
            </SwiperSlide>
          ))}

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </Swiper>
      </Container>
    </>
  )
}
export default SwipeSlider
